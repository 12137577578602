import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Step4Page() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "add-note.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-step-4/read')
    }, [])

    return (
        <>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.55,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="calendar example"
                        imgStyle={{objectFit: 'contain'}}
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="“Add note” to a specific date"
                subTitle={
                    <div style={{ maxWidth: 500, margin: 'auto', textAlign: 'left' }}>
                        A diary note can be a substantial supplement to your documentation.
                        <ul>
                            <li>
                                Choose “On Report” to include the note on your compliance report, or
                                deselect to keep the note private.
                            </li>
                            <li>
                                “On Graph” will make the note’s date visible on the Graph-page with
                                the selected color.
                            </li>
                        </ul>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/step3/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step5/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </>
    )
}
